@font-face {
  font-family: ABC Social Condensed;
  src: url("ABCSocialCondensed-Bold.6390ff85.otf");
  src: local(ABCSocialCondensed-Bold), url("ABCSocialCondensed-Bold.6390ff85.otf") format("opentype");
}

@font-face {
  font-family: ABC Social;
  src: url("ABCSocial-Regular.68c9f7eb.otf");
  src: local(ABCSocial-Regular), url("ABCSocial-Regular.68c9f7eb.otf") format("opentype");
}

@font-face {
  font-family: ABC Social Bold;
  src: url("ABCSocial-Bold.902fc0b1.otf");
  src: local(ABCSocial-Bold), url("ABCSocial-Bold.902fc0b1.otf") format("opentype");
}

@font-face {
  font-family: Inter;
  src: url("Inter-Regular.7cfebe77.ttf");
  src: local(Inter), url("Inter-Regular.7cfebe77.ttf") format("opentype");
}

.custom_amplitude {
  width: 60px;
  height: 60px;
  z-index: 100000;
  cursor: pointer;
  background-color: #333;
  border-radius: 50%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.custom_amplitude img {
  width: 30px;
  height: 30px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
  font-family: Inter, sans-serif;
}

input:focus {
  outline: 0;
}

body, header, footer {
  background-color: #101010;
  font-family: ABC Social Condensed, sans-serif;
}

h1 {
  height: 1px;
  width: 1px;
  border: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.scroll-block {
  overflow-y: hidden;
}

.d-none {
  display: none !important;
}

header {
  height: 105px;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 10px;
  display: flex;
  position: sticky;
  top: 0;
}

.container-block {
  width: 100%;
  max-width: 1032px;
  height: 325px;
  text-align: center;
  background-image: url("decorate-main.851c6370.svg");
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  font-family: ABC Social Condensed, sans-serif;
  font-style: normal;
  font-weight: 700;
  display: flex;
}

.main-logo {
  margin-right: 36px;
}

.left-header-block, .right-header-block {
  align-items: center;
  display: flex;
}

.menu-block {
  width: 350px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.menu-block a {
  color: #b2b2b2;
  font-size: 24px;
  font-weight: 700;
  line-height: 90%;
  text-decoration: none;
  transition: all .3s;
}

.menu-block a:hover {
  color: #f2f2f2;
}

.menu-block a:active {
  color: #f7f7f7;
}

.header-buttons-block {
  margin-left: 20px;
  display: flex;
}

.sign-up-button, .log-out-button, .log-in-button {
  width: 101px;
  text-align: center;
  color: #f7f7f7;
  height: 40px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  font-weight: 600;
  line-height: 120%;
  text-decoration: none;
  display: flex;
}

.sign-up-button {
  background: #6800ed;
  margin-right: 5px;
}

.log-in-button, .log-out-button {
  background: #333;
}

.header-socials {
  margin-right: 30px;
}

.streamLobby {
  width: 100%;
  max-width: 1032px;
  margin: 0 auto;
  font-family: Inter, sans-serif;
}

.hide {
  visibility: hidden !important;
}

.landing-sign-up-button {
  position: relative;
}

.spinner {
  width: 40px;
  min-height: 40px;
  visibility: visible;
  -ms-animation: loading 1.2s infinite linear;
  border: 6px solid #fff;
  border-left-color: #0000;
  border-radius: 50%;
  animation: 1.2s linear infinite loading;
  position: absolute;
  top: 9px;
}

@-webkit-keyframes loading {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes loading {
  to {
    -moz-transform: rotate(360deg);
  }
}



@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}

.direct-sign-up-error {
  color: #de3131;
  letter-spacing: -1px;
  padding-left: 8px;
  font-family: ABC Social Condensed, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 100%;
  position: absolute;
  bottom: -20px;
}

.streamLobbyHeader {
  color: #f7f7f7;
  text-align: center;
  max-width: 59%;
  margin: 80px auto 32px;
  font-family: ABC Social Condensed, sans-serif;
  font-size: 44px;
  font-weight: 400;
  line-height: 90%;
}

.streamLobbyDescription {
  color: #dfdfdf;
  width: 638px;
  margin-bottom: 48px;
  font-family: ABC Social, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 110%;
}

.streamLobbyFilter {
  flex-flow: wrap;
  align-items: center;
  gap: 8px;
  margin-bottom: 48px;
  display: flex;
}

.streamLobbyFilter input {
  display: none;
}

.streamLobbyFilter label {
  cursor: pointer;
  color: #f2f2f2;
  background: #262626;
  border-radius: 8px;
  padding: 16px;
  font-family: Inter, sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  display: inline-block;
}

.streamLobbyFilter input:checked + label {
  color: #262626;
  background: #dfdfdf;
}

.streamLobbyItemVideoCover {
  width: 100%;
  height: 100%;
}

.streamLobbyItemVideoCoverImage {
  width: inherit;
  height: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.streamLobbyItemPlayButton {
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: calc(50% - 23px);
  left: calc(50% - 32px);
}

.apex-bg-video:after {
  content: "";
  width: 140px;
  height: 136px;
  opacity: .6;
  background-image: url("apex-logo.a3f92fbd.svg");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.streamLobbyItemVideoCoverImage svg {
  cursor: pointer;
  z-index: 3;
  transition: all .3s;
}

.streamLobbyItemVideoCoverImage svg:hover {
  transition: all .3s;
  transform: scale(1.1);
}

.streamLobbyItemTopWrapper {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

.streamLobbyItemNickNamesWrapper {
  cursor: pointer;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
  position: relative;
}

.streamLobbyItemStreamHeader {
  letter-spacing: -2px;
  color: #fff;
  max-width: 270px;
  text-overflow: ellipsis;
  cursor: pointer;
  font-family: Blinker, sans-serif;
  font-size: 34px;
  font-weight: 800;
  line-height: 100%;
  text-decoration: underline;
  overflow: hidden;
}

.streamLobbyItemPrice {
  color: #fff;
  margin-left: auto;
  font-family: Blinker, sans-serif;
  font-size: 28px;
  line-height: 100%;
}

.streamLobbyItemServerAndGameBlock {
  margin-top: 3px;
}

.streamLobbyItemServerAndGameBlock > span {
  margin-right: 5px;
}

.streamLobbyItemServerAndGameBlockText {
  font-size: 17px;
  line-height: 130%;
}

.streamLobbyItemServerAndGameBlockHeader {
  color: #fff;
}

.streamLobbyItemServerAndGameBlockText {
  color: #fff9;
}

.rankPlayer {
  justify-content: center;
  align-items: center;
  display: flex;
}

.rankPlayer p {
  text-align: center;
  margin: 0 2px;
  font-size: 11px;
  font-weight: 800;
  line-height: 100%;
  position: relative;
}

.rank-vip {
  color: #f7be31;
  text-transform: uppercase;
}

.rank-vip svg path {
  fill: #f7be31;
}

.rank-pro {
  color: #beb5ff;
  text-transform: capitalize;
}

.rank-pro svg path {
  fill: #beb5ff;
}

.rank-semi-pro {
  color: #2ac9ff;
  text-transform: capitalize;
}

.rank-aspirant, .rank-rookie {
  color: #fff;
  text-transform: capitalize;
}

.rank-semi-pro svg path {
  fill: #2ac9ff;
}

.streamLobbyItemButtonText {
  color: #262626;
  margin: 0 5px 0 10px;
  font-family: ABC Social, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 110%;
}

.streamLobbyItemButtonValue {
  text-align: center;
  color: #262626;
  white-space: nowrap;
  font-family: ABC Social, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 110%;
}

.main-block {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 32px;
  padding-top: 43px;
  position: relative;
}

.container-block p {
  color: #f7f7f7;
  font-family: ABC Social Condensed, sans-serif;
  font-size: 100px;
  font-style: normal;
  font-weight: 700;
  line-height: 90%;
}

.container-block span {
  color: #f7f7f7;
  margin-top: 12px;
  font-family: ABC Social Condensed, sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 90%;
}

.main-info {
  color: #f7f7f7;
  max-width: 550px;
  margin-top: 24px;
  font-family: ABC Social, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 110%;
}

.main-header span:nth-child(2) {
  padding-left: 45px;
}

.main-header span:last-child {
  display: none;
}

.looking-for {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 112px;
}

.pros-list {
  width: 100%;
  color: #f7f7f7;
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
  display: flex;
}

.pros-list h3, .pros-list h2 {
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 90%;
}

.pros-list h3 {
  margin-bottom: 28px;
}

.player-card {
  cursor: pointer;
  max-width: 100%;
}

.looking-for-header {
  width: 100%;
  text-transform: uppercase;
  color: #262626;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  font-size: 56px;
  font-weight: 700;
  line-height: 90%;
  display: flex;
  position: relative;
}

.looking-for-header img {
  width: 100%;
  height: auto;
}

.looking-for-header p {
  text-align: center;
  position: absolute;
}

.looking-for-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.looking-for-left ul, .looking-for-right ul {
  color: #262626;
  max-width: 450px;
  font-family: ABC Social, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 110%;
  position: absolute;
}

.looking-for-left ul {
  right: 100px;
}

.looking-for-right ul {
  left: 100px;
}

.looking-for-left img, .looking-for-right img {
  width: 100%;
  height: auto;
}

.looking-for-left {
  width: 49%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: relative;
}

.looking-for-right {
  width: 49%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: relative;
}

.noPro {
  box-sizing: border-box;
  width: 100%;
  max-width: 1032px;
  background: #101010;
  border: 2px solid #262626;
  border-radius: 16px;
  margin: 25px auto 163px;
  padding: 32px 0;
}

.swiper {
  max-width: 1100px;
  margin: 0 !important;
  padding: 0 30px !important;
}

.carousel-container {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.swiper-button-prev, .swiper-button-next {
  min-width: 48px;
  min-height: 48px;
  background: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 15px 40px #00000026;
  z-index: 2 !important;
  color: #000 !important;
  margin: 0 !important;
  position: relative !important;
}

.swiper-button-prev {
  left: 55px !important;
}

.swiper-button-next {
  right: 55px !important;
}

.swiper-button-disabled {
  display: none !important;
}

.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 20px !important;
}

.noPro-header {
  color: #f7f7f7;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 16px;
  font-size: 40px;
  font-weight: 700;
  line-height: 90%;
}

.noPro-text {
  text-align: center;
  color: #dfdfdf;
  margin-bottom: 24px;
  font-family: ABC Social, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.jobBlock {
  width: 100%;
  max-width: 1032px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 112px;
  display: flex;
}

.jobItem {
  max-width: 100%;
  border: 2px solid #262626;
  border-radius: 16px;
  flex-direction: column;
  padding: 48px;
  transition: all .3s;
  display: flex;
}

.jobItem > a {
  margin-top: 32px;
}

.jobItem:hover {
  background: #262626;
}

.job-header {
  color: #f7f7f7;
  margin-bottom: 16px;
  font-size: 34px;
  font-weight: 700;
  line-height: 90%;
}

.job-text {
  color: #dfdfdf;
  font-family: ABC Social, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 110%;
}

.job-button {
  width: 131px;
  height: 60px;
  color: #262626;
  background: #24e543;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  font-weight: 600;
  line-height: 120%;
  text-decoration: none;
  transition: all .3s;
  display: flex;
}

.job-button:hover {
  background-color: #8be778;
}

.description {
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.description-block {
  width: 100%;
  max-width: 1032px;
  margin-top: 32px;
}

.description h2 {
  color: #f7f7f7;
  font-family: ABC Social Condensed, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 90%;
}

.descriptionOptions {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 48px;
  margin: 32px 0;
  display: flex;
}

.descriptionBasicText {
  color: #dfdfdf;
  align-self: stretch;
  margin-top: 16px;
  font-family: ABC Social, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.descriptionBasicTextHide {
  display: none;
}

.expandBtn, .collapseBtn {
  color: #7a7a7a;
  margin-left: 2px;
}

.descriptionBasicText p {
  display: block;
}

.descriptionOptions li {
  flex: 1;
  margin-left: 25px;
  display: flex;
  position: relative;
}

.descriptionOptions li:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #ff5a27;
  position: absolute;
  top: 3px;
  left: -24px;
}

.descriptionSecondText {
  color: #b2b2b2;
  margin-bottom: 64px;
  font-family: ABC Social, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.socials-block {
  width: 100%;
  max-width: 1032px;
  flex-direction: column;
  align-items: center;
  margin: 100px auto 112px;
  display: flex;
}

.socials-header {
  text-align: center;
  max-width: 582px;
  height: 130px;
  text-transform: uppercase;
  color: #f7f7f7;
  background-image: url("socials-header.067de451.svg");
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  padding: 30px;
  font-size: 40px;
  font-weight: 700;
  line-height: 90%;
  display: flex;
}

.socials-wrapper {
  width: 100%;
  max-width: 700px;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
  display: flex;
}

.socials-wrapper a {
  height: 71px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.socials-wrapper a img {
  width: 40px;
  height: auto;
}

.socials-wrapper a p {
  color: #b2b2b2;
  font-family: ABC Social, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
}

.game-selector {
  background-color: #101010;
  border: 1px solid #262626;
  border-radius: 12px;
  flex-direction: row;
  gap: 4px;
  padding: 4px;
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.game-selector:before {
  content: "Select your game";
  text-align: center;
  color: #f7f7f7;
  opacity: .4;
  white-space: nowrap;
  margin-top: 72px;
  font-family: ABC Social;
  font-size: 14px;
  font-weight: 400;
  line-height: 110%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.game-button {
  width: 140px;
  height: 64px;
  color: #fff;
  background-color: #262626;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 18px 16px;
  display: flex;
}

.game-button-active {
  background-color: #f7f7f7;
}

.game-button > img {
  width: auto;
}

.sign-up {
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  display: flex;
}

.sign-up span {
  color: #f7f7f7;
  font-family: ABC Social, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.sign-up-form {
  margin-top: 15px;
  display: flex;
  position: relative;
}

.sign-up-form input {
  height: 60px;
  width: 380px;
  color: #000;
  border: 1px solid #fff6;
  border-radius: 10px;
  margin-right: 8px;
  padding: 20px 16px;
  font-family: Inter, sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}

.sign-up-form input::placeholder {
  color: #000;
  opacity: .4;
  font-size: 19px;
  font-weight: 600;
  line-height: 100%;
}

footer {
  max-width: 1032px;
  width: 100%;
  border: 1px solid #262626;
  border-bottom: none;
  margin: 0 auto;
  padding: 64px;
}

.inner-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.payment-info {
  margin-top: 10px;
}

.footer-left {
  flex-direction: column;
  display: flex;
}

.footer-left, .footer-right {
  height: 192px;
}

.footer-left p {
  color: #b2b2b2;
  margin-top: auto;
  font-family: ABC Social, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.footer-right {
  flex-direction: column;
  display: flex;
}

.inner-right-wrapper {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.inner-right-wrapper div:first-child {
  margin-right: 92px;
}

.footer-right p {
  color: #7a7a7a;
  margin-bottom: 16px;
  font-family: ABC Social, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.footer-link {
  color: #f2f2f2;
  margin-bottom: 16px;
  font-family: ABC Social, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 110%;
  text-decoration: none;
  display: block;
}

.bottom-links {
  margin-top: auto;
}

.bottom-link {
  color: #7a7a7a;
  padding-right: 22px;
  font-family: ABC Social, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
}

.base-button {
  height: 60px;
  width: 100%;
  color: #f7f7f7;
  background-color: #333;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  font-weight: 600;
  line-height: 120%;
  display: flex;
}

.background-primary-500 {
  background-color: #6800ed;
}

.showAllButton {
  width: 220px;
  height: 80px;
  color: #f7f7f7;
  background: #6800ed;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-family: ABC Social, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-decoration: none;
  display: flex;
}

.popup-modal {
  height: auto;
  width: 100%;
  max-width: 600px;
  opacity: 0;
  pointer-events: none;
  z-index: 1011;
  background: #262626;
  border: 1px solid #333;
  border-radius: 16px;
  flex-direction: column;
  align-items: center;
  padding: 48px;
  transition: all .3s ease-in-out;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup-modal-signup {
  z-index: 1012;
}

.popup-modal.is--visible {
  opacity: 1;
  pointer-events: auto;
}

.popup-modal-header {
  color: #f2f2f2;
  text-transform: uppercase;
  white-space: nowrap;
  font-family: ABC Social Condensed, sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 90%;
}

.password-visibility-toggle {
  cursor: pointer;
  transition: all .3s;
  position: absolute;
  top: 50%;
  right: 13px;
  transform: translateY(-50%);
}

.password-error {
  top: initial;
  transform: initial;
  bottom: 15px;
}

.password-visible path {
  fill: #fff;
}

.password-input {
  padding-right: 35px !important;
}

.sign-in-header {
  margin-bottom: 32px;
}

.sign-up-header {
  margin-bottom: 16px;
}

.log-in-email-header {
  margin-bottom: 40px;
}

.password-recovery-header {
  margin-bottom: 16px;
  font-size: 48px;
}

.popup-modal-description {
  text-align: center;
  color: #f2f2f2;
  margin-bottom: 32px;
  font-family: ABC Social, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.popup-modal .discord-login-btn {
  width: 100%;
  height: 80px;
  color: #f2f2f2;
  cursor: pointer;
  background: #7289da;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  padding: 28px 32px;
  font-family: Inter, sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  transition: all .3s;
  display: flex;
}

.popup-modal .discord-login-btn svg {
  margin-right: 9px;
}

.popup-modal .discord-login-btn:hover {
  background: #6800ed;
}

.popup-modal .log-in-button-text {
  text-align: center;
  color: #f2f2f2;
  margin-bottom: 32px;
  font-family: ABC Social, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.popup-modal .log-in-button-text a {
  color: #2ac9ff;
  text-decoration: none;
}

.row-breaker {
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
  display: flex;
}

.row-breaker span {
  color: #f2f2f2;
  margin: 0 8px;
  font-family: ABC Social, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}

.row-breaker .row-breaker-line {
  width: 100%;
  border: 1px solid #4d4d4d;
}

.auth-buttons {
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
  display: flex;
}

.auth-buttons div:first-child {
  margin-right: 16px;
}

.auth-button {
  width: 100%;
  height: 60px;
  color: #dfdfdf;
  cursor: pointer;
  border: 2px solid #333;
  border-radius: 8px;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 18px 24px;
  font-family: Inter, sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  transition: all .3s;
  display: flex;
}

.auth-button:hover {
  border: 2px solid #565656;
}

.auth-button svg {
  margin-right: 8px;
}

.terms-links {
  color: #b2b2b2;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.terms-links a {
  color: #2ac9ff;
  text-decoration: none;
}

.body-blackout {
  z-index: 11;
  width: 100%;
  height: 100%;
  background-color: #000000a6;
  transition: all .3s ease-in-out;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.body-blackout.is-blacked-out {
  display: block;
}

.popup-modal__close {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: #0003;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 16px;
  right: 16px;
}

.sign-in-email-form {
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.sign-in-email-form .inputWrapper:first-child {
  margin-right: 16px;
}

.inputWrapper {
  width: 100%;
  position: relative;
}

.input-label {
  opacity: 0;
  color: #f2f2f2;
  font-family: ABC Social, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 3px;
  left: 15px;
}

.button--loading:after {
  content: "";
  width: 16px;
  height: 16px;
  border: 4px solid #0000;
  border-top-color: #fff;
  border-radius: 50%;
  margin: auto;
  animation: 1s infinite button-loading-spinner;
  position: absolute;
  inset: 0;
}

.button--loading span {
  display: none;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.error-tips {
  opacity: 1;
  top: 5px;
}

.basic-tips {
  opacity: 1;
  top: 10px;
}

.error-input {
  transition: all .3s ease-in-out;
  border: 1px solid #de3131 !important;
  border-top-width: 24px !important;
}

.error-warning {
  transition: all .3s ease-in-out;
  border: 1px solid #f2bd1b !important;
  border-top-width: 24px !important;
}

.input-with-label {
  transition: all .3s ease-in-out;
  border: 1px solid #0000 !important;
  border-top-width: 20px !important;
}

.sign-up-email-form {
  max-width: 432px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.sign-up-email-form input {
  max-width: 432px;
  width: 100%;
  height: 60px;
  color: #dfdfdf;
  background: #333;
  border: 1px solid #4d4d4d;
  border-radius: 4px;
  padding: 0 16px;
  font-family: ABC Social, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  transition: all .3s ease-in-out;
}

.sign-in-email-form input {
  width: 100%;
  height: 60px;
  color: #dfdfdf;
  background: #333;
  border: 1px solid #4d4d4d;
  border-radius: 4px;
  padding: 0 16px;
  font-family: ABC Social, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.submit-form-btn {
  width: 100%;
  height: 60px;
  text-align: center;
  color: #f7f7f7;
  background: #6800ed;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  padding: 18px 24px;
  font-family: Inter, sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  transition: all .3s ease-in-out;
  position: relative;
}

.log-in-form-button {
  max-width: 100%;
}

.sign-up-form-button {
  max-width: 432px;
}

.log-in-form-button:hover {
  background: #802dea;
}

.forgot-password-block {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

.forgot-password-text {
  color: #f2f2f2;
  font-family: ABC Social, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}

.forgot-password-link {
  color: #2ac9ff;
  font-family: ABC Social, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-decoration: none;
}

.action-btns {
  width: 100%;
  max-width: 432px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

.action-btn {
  width: 100%;
  height: 60px;
  text-align: center;
  color: #dfdfdf;
  background-color: #0000;
  border: 2px solid #333;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-family: Inter, sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  transition: all .3s ease-in-out;
  display: flex;
  position: relative;
}

.action-btn:hover {
  border: 2px solid #545454;
}

.send-pass {
  margin-right: 16px;
}

.recover-pass-login {
  background: #6800ed;
  border: none;
}

.recover-pass-login:hover {
  border: none;
}

.action-btn:disabled {
  cursor: initial;
  background: #b2b2b2;
  border: none;
}

.action-btn:disabled:hover {
  background: #b2b2b2;
  border: none;
}

.back-to-login {
  text-align: center;
  color: #f2f2f2;
  font-family: ABC Social, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.back-to-login a {
  color: #2ac9ff;
  text-decoration: none;
}

.showOnMobile, .looking-for-tablet, .mobile-copyright, .looking-for-mobile {
  display: none;
}

.looking-for-left ul li, .looking-for-right ul li {
  max-width: 395px;
  margin-bottom: 16px;
}

.hidden {
  display: none !important;
}

.trustPilot {
  width: 100%;
  max-width: 1088px;
  margin: 0 auto 90px;
}

.trustPilot-subheader {
  justify-content: center;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 32px;
  display: flex;
}

.trustPilot-header {
  max-width: 850px;
  text-align: center;
  color: #f7f7f7;
  margin: 0 auto;
  font-family: ABC Social Condensed, sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 90%;
}

.trustPilot-text {
  color: #f8f9fa;
  margin-top: 20px;
  margin-left: 15px;
  font-family: ABC Social, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.trustPilot-text span, .trustPilot-text a {
  color: #f2c94c;
}

.feedbackWrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.feedbackStarsContainer {
  align-items: center;
  margin-bottom: 15px;
  display: flex;
}

.feedbackStarsContainer img {
  margin-right: 10px;
}

.feedbackItem {
  width: 350px;
  height: 404px;
  background: #262626;
  border-radius: 10px;
  flex-direction: column;
  padding: 22px 22px 30px;
  font-family: ABC Social Condensed, sans-serif;
  display: flex;
}

.feedbackHeader {
  color: #f7f7f7;
  margin-bottom: 10px;
  font-family: ABC Social Condensed, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 90%;
}

.feedbackText {
  color: #dfdfdf;
  margin-bottom: auto;
  font-family: ABC Social, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.feedbackNickName {
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 130%;
}

.game-selector-mobile {
  display: none;
}

@media (max-width: 850px) {
  .custom_amplitude {
    bottom: 60px;
  }

  .showOnDesktop {
    display: none;
  }

  .showOnMobile {
    align-self: flex-start;
    display: block;
  }

  .menu-block, .header-socials {
    display: none;
  }

  .header-buttons-block {
    position: relative;
  }

  .main-header {
    height: 180px;
    max-width: 630px;
    margin-left: 0;
  }

  .main-header span:not(:last-child) {
    font-size: 48px;
    line-height: 90%;
  }

  .main-info {
    margin-top: 35px;
    margin-left: 48px;
  }

  .main-block {
    height: auto;
    background-size: contain;
    padding-top: 101px;
  }

  .streamLobby {
    padding: 0 48px;
  }

  .streamLobbyHeader {
    max-width: 100%;
    margin: 50px 0 26px;
    font-size: 34px;
    font-weight: 700;
    line-height: 90%;
  }

  .noPro {
    width: auto;
    margin: 0 48px;
  }

  .noPro-header {
    text-transform: none;
  }

  .jobBlock {
    gap: 0 24px;
    margin: 0 auto 128px;
    padding: 0 48px;
  }

  .jobItem {
    min-width: 49%;
    padding: 24px;
  }

  .job-header {
    font-size: 24px;
  }

  .job-text {
    font-size: 16px;
  }

  footer {
    width: auto;
    margin: 0 48px;
    padding: 64px 48px;
  }

  .inner-wrapper {
    flex-wrap: wrap;
  }

  .footer-right {
    height: auto;
    margin: 0 auto;
  }

  .bottom-links {
    margin-top: 48px;
  }

  .looking-for {
    display: none;
  }

  .looking-for-tablet {
    margin-top: 96px;
    margin-bottom: 160px;
    display: block;
  }

  .looking-for-left {
    width: 100%;
    justify-content: center;
  }

  .streamLobbyDescription {
    width: 100%;
  }

  .streamLobbyFilter {
    border: 1px solid #ffffff1a;
    border-radius: 8px;
    flex-flow: wrap;
    align-items: center;
    gap: 10px;
    padding: 4px;
    display: flex;
  }

  .streamLobbyFilter label {
    white-space: nowrap;
    margin: 0;
    padding: 4px 8px;
    font-size: 19px;
  }

  .streamLobbyFilter input:checked + label {
    color: #fff;
    background: #6800ed;
  }

  footer .mobile-copyright {
    color: #b2b2b2;
    margin-top: 32px;
    margin-bottom: 0;
    font-family: ABC Social, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 110%;
    display: none;
  }

  .swiper-slide {
    -webkit-filter: grayscale(0) opacity() !important;
    transform: scale(1) !important;
  }

  .socials-wrapper {
    padding: 0 48px;
  }

  .looking-for-left ul {
    right: initial;
  }

  .streamLobbyItemTopWrapper {
    padding: 0 16px;
  }

  .streamLobbyItemPrice {
    display: none;
  }

  .streamLobbyItemNickNamesWrapper {
    width: 100%;
  }

  .streamLobbyItemStreamHeader {
    max-width: 90%;
  }

  .streamLobbyItemButtonText, .streamLobbyItemButtonValue {
    display: none;
  }

  .popup-modal {
    height: -moz-fit-content;
    height: fit-content;
    left: initial;
    bottom: 0;
    top: initial;
    border: 1px solid #333;
    border-radius: 16px 16px 0 0;
    padding: 36px 16px 24px;
    position: fixed;
    transform: none;
  }

  .popup-modal-header {
    margin-bottom: 8px;
    font-size: 40px;
  }

  .sign-in-header, .log-in-email-header {
    margin-bottom: 24px;
  }

  .sign-in-email-form {
    flex-direction: column;
  }

  .sign-in-email-form .inputWrapper:first-child {
    margin-bottom: 16px;
    margin-right: 0;
  }

  .forgot-password-block {
    justify-content: center;
  }

  .log-in-form-button {
    margin-top: 24px;
  }

  .password-recovery-header {
    text-align: center;
    margin-bottom: 16px;
    font-size: 40px;
  }

  .popup-modal-description {
    margin-bottom: 24px;
    font-size: 16px;
  }

  .action-btns {
    flex-direction: column-reverse;
  }

  .send-pass {
    margin-right: 0;
  }

  .recover-pass-login {
    margin-bottom: 16px;
  }

  .popup-modal .discord-login-btn {
    height: 60px;
  }

  .popup-modal .log-in-button-text {
    margin-bottom: 24px;
  }

  .auth-buttons {
    flex-direction: column;
    margin-bottom: 24px;
  }

  .auth-buttons div:first-child {
    margin-bottom: 16px;
    margin-right: 0;
  }

  .terms-links {
    text-align: center;
    max-width: 210px;
    line-height: 110%;
  }

  .description-block {
    padding: 0 16px;
  }
}

@media (max-width: 725px) {
  .footer-left {
    height: auto;
    margin-bottom: 40px;
  }

  .footer-left p {
    display: none;
  }

  .mobile-copyright {
    display: block !important;
  }
}

@media (max-width: 590px) {
  .showOnDesktop {
    display: none !important;
  }

  .main-block {
    padding-top: 0;
  }

  .game-selector {
    transform: initial;
    left: initial;
    display: none;
    position: sticky;
  }

  .main-logo {
    width: 111px;
    height: 30px;
    margin-right: 0;
  }

  header {
    height: 48px;
    padding: 0 16px;
  }

  footer {
    margin: 0;
    padding: 32px 16px 16px;
  }

  .jobBlock {
    flex-wrap: wrap;
    gap: 24px 0;
    margin-bottom: 32px;
    padding: 0 16px;
  }

  .jobItem {
    width: 100%;
  }

  .description-block {
    max-width: initial;
    padding: 0 16px;
  }

  .descriptionBasicText {
    font-size: 14px;
  }

  .descriptionOptions {
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
    margin: 24px 0;
  }

  .descriptionSecondText {
    margin-bottom: 48px;
  }

  .socials-block {
    margin-top: 50px;
    margin-bottom: 48px;
  }

  .socials-wrapper {
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0;
  }

  .socials-wrapper a {
    width: 33%;
    margin-bottom: 20px;
  }

  .main-info {
    margin-top: 16px;
    margin-left: 16px;
  }

  .main-header span:not(:last-child) {
    font-size: 40px;
    line-height: 90%;
  }

  .main-header {
    background-image: url("main-header-mobile.9984eca9.svg");
    background-size: 100%;
    padding-left: 16px;
  }

  .main-header span:last-child {
    text-transform: uppercase;
    color: #f7f7f7;
    font-size: 37px;
    font-weight: 700;
    line-height: 90%;
    display: block;
  }

  .streamLobby {
    padding: 0 16px;
  }

  .noPro {
    margin: 0 16px;
    padding: 16px;
  }

  .noPro-header {
    font-size: 24px;
    line-height: 90%;
  }

  .noPro-text {
    font-size: 16px;
  }

  .looking-for-mobile {
    margin-top: 84px;
    margin-bottom: 33px;
    display: block;
  }

  .looking-for-tablet {
    display: none;
  }

  .looking-for-header {
    font-size: 40px;
    line-height: 90%;
  }

  .looking-for-left ul {
    padding: 0 16px;
    left: 20px;
  }

  .socials-header {
    text-transform: none;
    font-size: 34px;
    line-height: 90%;
  }

  .main-header {
    max-width: 83%;
  }

  .footer-right, .job-button {
    width: 100%;
  }

  .feedbackWrapper {
    padding: 0 12px;
    display: block;
  }

  .feedbackItem {
    width: 100%;
    height: auto;
    margin-bottom: 8px;
    padding-bottom: 19px;
  }

  .trustPilot-header {
    max-width: 95%;
    font-size: 34px;
    line-height: 90%;
  }

  .trustPilot-subheader {
    text-align: center;
    margin-bottom: 16px;
    display: block;
  }

  .trustPilot-subheader img {
    width: 164px;
  }

  .trustPilot-text {
    margin-left: 0;
    font-size: 18px;
  }

  .feedbackText {
    margin-bottom: 10px;
  }

  .trustPilot {
    margin-bottom: 60px;
  }

  .container-block {
    height: 180px;
    background-size: contain;
  }

  .container-block p {
    font-size: 44px;
  }

  .container-block span {
    font-size: 20px;
    font-weight: 700;
    line-height: 90%;
  }

  .main-block > .sign-up {
    margin-top: 5px;
  }

  .sign-up {
    padding: 0 16px;
  }

  .sign-up-form {
    width: 100%;
    flex-direction: column;
  }

  .sign-up-form > input {
    width: 100%;
  }

  .sign-up-form > button {
    width: 100%;
    margin-top: 10px;
  }

  .looking-for-left ul {
    font-weight: 400;
  }

  .game-selector-mobile {
    z-index: 2;
    justify-content: center;
    display: flex;
    position: sticky;
    bottom: 0;
  }

  .game-selector-mobile > .game-selector {
    max-width: 100%;
    display: flex;
    overflow-x: scroll;
  }

  .game-selector-mobile > .game-selector > a {
    min-width: 86px;
    height: 40px;
    padding: 9px 11px;
  }

  .pros-list h3 {
    margin-bottom: 12px;
    font-size: 30px;
  }

  .swiper-button-next, .swiper-button-prev {
    display: none !important;
  }

  .showAllButton {
    height: 60px;
    width: 95%;
    font-size: 20px;
    font-weight: 700;
    line-height: 110%;
  }

  .game-button > img {
    width: 100%;
  }

  .game-selector:before {
    display: none;
  }
}

@media (max-width: 450px) {
  .container-block p {
    font-size: 35px;
  }
}

.container-block {
  display: none;
}

/*# sourceMappingURL=index.8eb25e0b.css.map */
