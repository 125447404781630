@font-face {
    font-family: 'ABC Social Condensed';
    src: url('../fonts-main-page/ABCSocialCondensed-Bold.otf');
    src: local('ABCSocialCondensed-Bold'), url('../fonts-main-page/ABCSocialCondensed-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'ABC Social';
    src: url('../fonts-main-page/ABCSocial-Regular.otf');
    src: local('ABCSocial-Regular'), url('../fonts-main-page/ABCSocial-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'ABC Social Bold';
    src: url('../fonts-main-page/ABCSocial-Bold.otf');
    src: local('ABCSocial-Bold'), url('../fonts-main-page/ABCSocial-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts-main-page/Inter-Regular.ttf');
    src: local('Inter'), url('../fonts-main-page/Inter-Regular.ttf') format('opentype');
}

.custom_amplitude {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #333333;
    z-index: 100000;
    cursor: pointer;
}

.custom_amplitude img {
    width: 30px;
    height: 30px;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

button {
    font-family: 'Inter', sans-serif;
    border: none;
    outline: none;
    cursor: pointer;
}

input:focus {
    outline: 0;
}

body,
header,
footer {
    background-color: #101010;
    font-family: 'ABC Social Condensed', sans-serif;
}

h1 {
    position: absolute;
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0;
}

.scroll-block {
    overflow-y: hidden;
}

.d-none {
    display: none !important;
}

header {
    height: 105px;
    padding: 0 24px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 10;
}

.container-block {
    width: 100%;
    max-width: 1032px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    background-image: url("../img-main-page/decorate-main.svg");
    background-repeat: no-repeat;
    background-position: center center;
    height: 325px;
    flex-direction: column;
    text-align: center;
    font-family: 'ABC Social Condensed', sans-serif;
    font-style: normal;
    font-weight: 700;
}

.main-logo {
    margin-right: 36px;
}

.left-header-block,
.right-header-block {
    display: flex;
    align-items: center;
}

.menu-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 350px;
}

.menu-block a {
    font-weight: 700;
    font-size: 24px;
    line-height: 90%;
    color: #B2B2B2;
    text-decoration: none;
    transition: .3s all;
}

.menu-block a:hover {
    color: #F2F2F2;
}

.menu-block a:active {
    color: #F7F7F7;
}

.header-buttons-block {
    margin-left: 20px;
    display: flex;
}

.sign-up-button,
.log-out-button,
.log-in-button {
    width: 101px;
    font-weight: 600;
    font-size: 19px;
    line-height: 120%;
    text-align: center;
    color: #F7F7F7;
    border-radius: 8px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.sign-up-button {
    background: #6800ED;
    margin-right: 5px;
}

.log-in-button {
    background: #333333;
}

.log-out-button {
    background: #333333;
}

.header-socials {
    margin-right: 30px;
}

.streamLobby {
    width: 100%;
    max-width: 1032px;
    margin: 0 auto;
    font-family: 'Inter', sans-serif;
}
.hide {
    visibility: hidden !important;
}

.landing-sign-up-button {
    position: relative;
}

.spinner {
    position: absolute;
    top: 9px;
    width: 40px;
    min-height: 40px;
    border-radius: 50%;
    visibility: visible;
    border-top: 6px solid #ffffff;
    border-right: 6px solid #ffffff;
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
    -webkit-animation: loading 1.2s infinite linear;
    -moz-animation: loading 1.2s infinite linear;
    -ms-animation: loading 1.2s infinite linear;
    animation: loading 1.2s infinite linear;
}

@-webkit-keyframes loading {
    to { -webkit-transform: rotate(360deg); }
}

@-moz-keyframes loading {
    to { -moz-transform: rotate(360deg); }
}

@-ms-keyframes loading {
    to { -ms-transform: rotate(360deg); }
}

@keyframes loading {
    to { transform: rotate(360deg); }
}

.direct-sign-up-error {
    position: absolute;
    bottom: -20px;
    padding-left: 8px;
    color: #DE3131;
    letter-spacing: -1px;
    font-family: ABC Social Condensed, sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 100%;
}

.streamLobbyHeader {
    font-family: 'ABC Social Condensed', sans-serif;
    font-weight: 400;
    font-size: 44px;
    line-height: 90%;
    color: #F7F7F7;
    text-align: center;
    max-width: 59%;
    margin: 80px auto 32px;
}

.streamLobbyDescription {
    font-family: 'ABC Social', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    color: #DFDFDF;
    width: 638px;
    margin-bottom: 48px;
}

.streamLobbyFilter {
    margin-bottom: 48px;
    flex-direction: row;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
}

.streamLobbyFilter input {
    display: none;
}

.streamLobbyFilter label {
    background: #262626;
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 120%;
    color: #F2F2F2;
    display: inline-block;
}

.streamLobbyFilter input:checked + label {
    background: #DFDFDF;
    color: #262626;
}

.streamLobbyItemVideoCover {
    width: 100%;
    height: 100%;
}

.streamLobbyItemVideoCoverImage {
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.streamLobbyItemPlayButton {
    position: absolute;
    cursor: pointer;
    top: calc(50% - 23px);
    left: calc(50% - 32px);
    z-index: 1;
}

.apex-bg-video::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 140px;
    height: 136px;
    transform: translate(-50%, -50%);
    background-image: url('../img-main-page/apex-logo.svg');
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.6;
}

.streamLobbyItemVideoCoverImage svg {
    cursor: pointer;
    transition: .3s;
    z-index: 3;
}

.streamLobbyItemVideoCoverImage svg:hover {
    transform: scale(1.1);
    transition: .3s;
}

.streamLobbyItemTopWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.streamLobbyItemNickNamesWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;
    position: relative;
}

.streamLobbyItemStreamHeader {
    font-family: 'Blinker', sans-serif;
    letter-spacing: -2px;
    font-weight: 800;
    font-size: 34px;
    line-height: 100%;
    color: #FFFFFF;
    max-width: 270px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: underline;
    cursor: pointer;
}

.streamLobbyItemPrice {
    font-family: 'Blinker', sans-serif;
    font-size: 28px;
    line-height: 100%;
    color: #FFFFFF;
    margin-left: auto;
}

.streamLobbyItemServerAndGameBlock {
    margin-top: 3px;
}

.streamLobbyItemServerAndGameBlock > span {
    margin-right: 5px;
}

.streamLobbyItemServerAndGameBlockText {
    font-size: 17px;
    line-height: 130%;
}

.streamLobbyItemServerAndGameBlockHeader {
    color: #ffffff;
}

.streamLobbyItemServerAndGameBlockText {
    color: rgba(255, 255, 255, 0.6);
}

.rankPlayer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.rankPlayer p {
    font-weight: 800;
    font-size: 11px;
    line-height: 100%;
    text-align: center;
    position: relative;
    margin: 0 2px;
}

.rank-vip {
    color: #F7BE31;
    text-transform: uppercase;
}

.rank-vip svg path {
    fill: #F7BE31;
}

.rank-pro {
    color: #BEB5FF;
    text-transform: capitalize;
}

.rank-pro svg path {
    fill: #BEB5FF;
}

.rank-semi-pro {
    color: #2AC9FF;
    text-transform: capitalize;
}

.rank-aspirant {
    color: #ffffff;
    text-transform: capitalize;
}

.rank-rookie {
    color: #ffffff;
    text-transform: capitalize;
}

.rank-semi-pro svg path {
    fill: #2AC9FF;
}

.streamLobbyItemButtonText {
    font-family: 'ABC Social', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 110%;
    color: #262626;
    margin: 0 5px 0 10px;
}

.streamLobbyItemButtonValue {
    font-family: 'ABC Social', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    color: #262626;
    white-space: nowrap;

}

.main-block {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 43px;
    margin-bottom: 32px;
}

/*.main-block-warzone {*/
/*    background-image: url('../img-main-page/warzone/main-bg.png');*/
/*}*/

/*.main-block-warzone2 {*/
/*    background-image: url('../img-main-page/warzone2/main-bg.jpg');*/
/*}*/

/*.main-block-apex {*/
/*    background-image: url('../img-main-page/apex/main-bg.jpg');*/
/*}*/

/*.main-block-destiny {*/
/*    background-image: url('../img-main-page/destiny/main-bg.jpg');*/
/*}*/

/*.main-block-valorant {*/
/*    background-image: url('../img-main-page/valorant/main-bg.webp');*/
/*}*/

/*.main-block-fortnite {*/
/*    background-image: url('../img-main-page/fortnite/main-bg.webp');*/
/*}*/

.container-block p {
    font-family: 'ABC Social Condensed', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 100px;
    line-height: 90%;
    color: #F7F7F7;
}

.container-block span {
    font-family: 'ABC Social Condensed', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 90%;
    color: #F7F7F7;
    margin-top: 12px;
}

.main-info {
    font-family: 'ABC Social', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    margin-top: 24px;
    color: #F7F7F7;
    max-width: 550px;
}

.main-header span:nth-child(2) {
    padding-left: 45px;
}

.main-header span:last-child {
    display: none;
}

.looking-for {
    margin-top: 100px;
    margin-bottom: 112px;
    width: 100%;
}

.pros-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #F7F7F7;
    align-items: center;
    text-align: center;
    margin-top: 120px;
}

.pros-list h3,
.pros-list h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 90%;
}

.pros-list h3 {
    margin-bottom: 28px;
}

.player-card {
    cursor: pointer;
    max-width: 100%;
}

.looking-for-header {
    position: relative;
    width: 100%;
    font-weight: 700;
    font-size: 56px;
    line-height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #262626;
    margin-bottom: 16px;
}

.looking-for-header img {
    width: 100%;
    height: auto;
}

.looking-for-header p {
    position: absolute;
    text-align: center;
}

.looking-for-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.looking-for-left ul,
.looking-for-right ul {
    font-family: 'ABC Social', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 110%;
    color: #262626;
    max-width: 450px;
    position: absolute;
}

.looking-for-left ul {
    right: 100px;
}

.looking-for-right ul {
    left: 100px;
}

.looking-for-left img,
.looking-for-right img {
    width: 100%;
    height: auto;
}

.looking-for-left {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 49%;
    position: relative;
}

.looking-for-right {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 49%;
    position: relative;
}

.noPro {
    background: #101010;
    border: 2px solid #262626;
    box-sizing: border-box;
    border-radius: 16px;
    width: 100%;
    max-width: 1032px;
    margin: 25px auto 163px;
    padding: 32px 0;
}

.swiper {
    max-width: 1100px;
    padding: 0 30px !important;
    margin: 0 !important;
}

.carousel-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.swiper-button-prev,
.swiper-button-next {
    z-index: 2 !important;
    position: relative !important;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 48px;
    min-height: 48px;
    background: #FFFFFF;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    color: #000000 !important;
    margin: 0 !important;
}

.swiper-button-prev {
    left: 55px !important;
}

.swiper-button-next {
    right: 55px !important;
}

.swiper-button-disabled {
    display: none !important;
}

.swiper-button-prev::after {
    font-size: 20px !important;
}

.swiper-button-next::after {
    font-size: 20px !important;
}

.noPro-header {
    font-weight: 700;
    font-size: 40px;
    line-height: 90%;
    color: #F7F7F7;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.noPro-text {
    text-align: center;
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    color: #DFDFDF;
    margin-bottom: 24px;
}

.jobBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1032px;
    margin: 0 auto 112px;
}

.jobItem {
    border: 2px solid #262626;
    border-radius: 16px;
    padding: 48px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    transition: .3s all;
}

.jobItem > a {
    margin-top: 32px;
}

.jobItem:hover {
    background: #262626;
}

.job-header {
    font-weight: 700;
    font-size: 34px;
    line-height: 90%;
    color: #F7F7F7;
    margin-bottom: 16px;
}

.job-text {
    font-family: "ABC Social", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    color: #DFDFDF;
}

.job-button {
    background: #24E543;
    border-radius: 8px;
    width: 131px;
    height: 60px;
    font-weight: 600;
    font-size: 19px;
    line-height: 120%;
    color: #262626;
    transition: .3s all;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.job-button:hover {
    background-color: #8BE778;
}

.description {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.description-block {
    width: 100%;
    max-width: 1032px;
    margin-top: 32px;
}

.description h2 {
    font-family: 'ABC Social Condensed', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 90%;
    color: #F7F7F7;
}

.descriptionOptions {
    width: 100%;
    display: flex;
    gap: 48px;
    flex-direction: row;
    justify-content: space-between;
    margin: 32px 0;
}

.descriptionBasicText {
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    color: #DFDFDF;
    margin-top: 16px;
    align-self: stretch;
}

.descriptionBasicTextHide {
    display: none;
}

.expandBtn,
.collapseBtn {
    color: #7A7A7A;
    margin-left: 2px;
}

.descriptionBasicText p {
    display: block;
}

.descriptionOptions li {
    display: flex;
    flex: 1;
    position: relative;
    margin-left: 25px;
}

.descriptionOptions li::after {
    position: absolute;
    top: 3px;
    left: -24px;
    content: '';
    width: 8px;
    height: 8px;
    background: #FF5A27;
}

.descriptionSecondText {
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    color: #B2B2B2;
    margin-bottom: 64px;
}

.socials-block {
    width: 100%;
    max-width: 1032px;
    margin: 100px auto 112px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.socials-header {
    background-image: url('../img-main-page/socials-header.svg');
    background-repeat: no-repeat;
    text-align: center;
    max-width: 582px;
    height: 130px;
    font-weight: 700;
    font-size: 40px;
    line-height: 90%;
    text-transform: uppercase;
    color: #F7F7F7;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.socials-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 700px;
    margin-top: 80px;
}

.socials-wrapper a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 71px;
    text-decoration: none;
}

.socials-wrapper a img {
    width: 40px;
    height: auto;
}

.socials-wrapper a p {
    font-family: 'ABC Social', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    color: #B2B2B2;
}

.game-selector {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    background-color: #101010;
    border: 1px solid #262626;
    border-radius: 12px;
    padding: 4px;
    gap: 4px;
}

.game-selector:before{
    content: "Select your game";
    text-align: center;
    color: #f7f7f7;
    opacity: .4;
    margin-top: 72px;
    font-family: ABC Social;
    font-size: 14px;
    font-weight: 400;
    line-height: 110%;
    position: absolute;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
}

.game-button {
    width: 140px;
    height: 64px;
    border-radius: 8px;
    background-color: #262626;
    padding: 18px 16px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.game-button-active {
    background-color: #F7F7F7;
}

.game-button > img {
    width: auto;
}

.sign-up {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 40px;
    align-items: center;
}

.sign-up span {
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    color: #F7F7F7;
}

.sign-up-form {
    position: relative;
    margin-top: 15px;
    display: flex;
}

.sign-up-form input {
    height: 60px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    padding: 20px 16px;
    width: 380px;
    margin-right: 8px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 100%;
    color: #000000;
}

.sign-up-form input::placeholder {
    font-weight: 600;
    font-size: 19px;
    line-height: 100%;
    color: #000000;
    opacity: 0.4;
}

footer {
    max-width: 1032px;
    margin: 0 auto;
    width: 100%;
    border: 1px solid #262626;
    border-bottom: none;
    padding: 64px;
}

.inner-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.payment-info{
    margin-top: 10px;
}

.footer-left {
    display: flex;
    flex-direction: column;
}

.footer-left,
.footer-right {
    height: 192px;
}

.footer-left p {
    margin-top: auto;
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    color: #B2B2B2;
}

.footer-right {
    display: flex;
    flex-direction: column;
}

.inner-right-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.inner-right-wrapper div:first-child {
    margin-right: 92px;
}

.footer-right p {
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    color: #7A7A7A;
    margin-bottom: 16px;
}

.footer-link {
    font-family: 'ABC Social', sans-serif;
    display: block;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 18px;
    line-height: 110%;
    color: #F2F2F2;
    text-decoration: none;
}

.bottom-links {
    margin-top: auto;
}

.bottom-link {
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 110%;
    color: #7A7A7A;
    text-decoration: none;
    padding-right: 22px;
}

.base-button {
    height: 60px;
    width: 100%;
    border-radius: 8px;
    font-weight: 600;
    font-size: 19px;
    line-height: 120%;
    color: #F7F7F7;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333333;
}

.background-primary-500 {
    background-color: #6800ED;
}

.showAllButton {
    width: 220px;
    height: 80px;
    background: #6800ED;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin: 0 auto;
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    color: #F7F7F7;
}

.popup-modal {
    height: auto;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #262626;
    border: 1px solid #333333;
    border-radius: 16px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 48px;
    opacity: 0;
    pointer-events: none;
    transition: all 300ms ease-in-out;
    z-index: 1011;
}

.popup-modal-signup {
    z-index: 1012;
}

.popup-modal.is--visible {
    opacity: 1;
    pointer-events: auto;
}

.popup-modal-header {
    font-family: 'ABC Social Condensed', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 90%;
    color: #F2F2F2;
    text-transform: uppercase;
    white-space: nowrap;
}

.password-visibility-toggle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 13px;
    cursor: pointer;
    transition: .3s;
}

.password-error {
    top: initial;
    transform: initial;
    bottom: 15px;
}

.password-visible path {
    fill: #ffffff;
}

.password-input {
    padding-right: 35px !important;
}

.sign-in-header {
    margin-bottom: 32px;
}

.sign-up-header {
    margin-bottom: 16px;
}

.log-in-email-header {
    margin-bottom: 40px;
}

.password-recovery-header {
    font-size: 48px;
    margin-bottom: 16px;
}

.popup-modal-description {
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    text-align: center;
    color: #F2F2F2;
    margin-bottom: 32px;
}

.popup-modal .discord-login-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 28px 32px;
    width: 100%;
    height: 80px;
    background: #7289DA;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 120%;
    color: #F2F2F2;
    cursor: pointer;
    transition: .3s;
    margin-bottom: 16px;
}

.popup-modal .discord-login-btn svg {
    margin-right: 9px;
}

.popup-modal .discord-login-btn:hover {
    background: #6800ED;
}

.popup-modal .log-in-button-text {
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    color: #F2F2F2;
    margin-bottom: 32px;
}

.popup-modal .log-in-button-text a {
    text-decoration: none;
    color: #2AC9FF;
}

.row-breaker {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 32px;
}

.row-breaker span {
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 110%;
    color: #F2F2F2;
    margin: 0 8px;
}

.row-breaker .row-breaker-line {
    width: 100%;
    border: 1px solid #4D4D4D;
}

.auth-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 32px;
}

.auth-buttons div:first-child {
    margin-right: 16px;
}

.auth-button {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px 24px;
    height: 60px;
    border: 2px solid #333333;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 120%;
    color: #DFDFDF;
    cursor: pointer;
    transition: .3s;
}

.auth-button:hover {
    border: 2px solid #565656;
}

.auth-button svg {
    margin-right: 8px;
}

.terms-links {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 130%;
    color: #B2B2B2;
}

.terms-links a {
    text-decoration: none;
    color: #2AC9FF;
}

.body-blackout {
    position: fixed;
    z-index: 11;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    display: none;
    transition: all 300ms ease-in-out;
}

.body-blackout.is-blacked-out {
    display: block;
}

.popup-modal__close {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 32px;
    height: 32px;
    right: 16px;
    top: 16px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.sign-in-email-form {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
}

.sign-in-email-form .inputWrapper:first-child {
    margin-right: 16px;
}

.inputWrapper {
    width: 100%;
    position: relative;
}

.input-label {
    position: absolute;
    top: 3px;
    left: 15px;
    opacity: 0;
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 110%;
    transition: all 300ms ease-in-out;
    color: #F2F2F2;
}

.button--loading::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
}

.button--loading span {
    display: none;
}

@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}

.error-tips {
    opacity: 1;
    top: 5px;
}

.basic-tips {
    opacity: 1;
    top: 10px;
}

.error-input {
    border-top: 24px solid #DE3131 !important;
    border-bottom: 1px solid #DE3131 !important;
    border-left: 1px solid #DE3131 !important;
    border-right: 1px solid #DE3131 !important;
    transition: all 300ms ease-in-out;
}

.error-warning {
    border-top: 24px solid #F2BD1B !important;
    border-bottom: 1px solid #F2BD1B !important;
    border-left: 1px solid #F2BD1B !important;
    border-right: 1px solid #F2BD1B !important;
    transition: all 300ms ease-in-out;
}

.input-with-label {
    border-top: 20px solid transparent !important;
    border-bottom: 1px solid transparent !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
    transition: all 300ms ease-in-out;
}

.sign-up-email-form {
    max-width: 432px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
}

.sign-up-email-form input {
    max-width: 432px;
    width: 100%;
    height: 60px;
    background: #333333;
    border: 1px solid #4D4D4D;
    border-radius: 4px;
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    color: #DFDFDF;
    padding: 0 16px;
    transition: all 300ms ease-in-out;
}

.sign-in-email-form input {
    width: 100%;
    height: 60px;
    background: #333333;
    border: 1px solid #4D4D4D;
    border-radius: 4px;
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    color: #DFDFDF;
    padding: 0 16px;
}

.submit-form-btn {
    position: relative;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px 24px;
    height: 60px;
    background: #6800ED;
    border-radius: 8px;
    transition: all 300ms ease-in-out;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 120%;
    text-align: center;
    color: #F7F7F7;
    margin-bottom: 24px;
}

.log-in-form-button {
    max-width: 100%;
}

.sign-up-form-button {
    max-width: 432px;
}

.log-in-form-button:hover {
    background: #802dea;
}

.forgot-password-block {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.forgot-password-text {
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 110%;
    color: #F2F2F2;
}

.forgot-password-link {
    text-decoration: none;
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 110%;
    color: #2AC9FF;
}

.action-btns {
    width: 100%;
    max-width: 432px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
}

.action-btn {
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    border: 2px solid #333333;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 120%;
    text-align: center;
    color: #DFDFDF;
    transition: all 300ms ease-in-out;
    position: relative;
}

.action-btn:hover {
    border: 2px solid #545454;
}

.send-pass {
    margin-right: 16px;
}

.recover-pass-login {
    background: #6800ED;
    border: none;
}

.recover-pass-login:hover {
    border: none;
}

.action-btn:disabled {
    cursor: initial;
    border: none;
    background: #B2B2B2;
}

.action-btn:disabled:hover {
    border: none;
    background: #B2B2B2;
}

.back-to-login {
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    text-align: center;
    color: #F2F2F2;
}

.back-to-login a {
    color: #2AC9FF;
    text-decoration: none;
}

.showOnMobile {
    display: none;
}

.looking-for-tablet,
.mobile-copyright,
.looking-for-mobile {
    display: none;
}

.looking-for-left ul li,
.looking-for-right ul li {
    margin-bottom: 16px;
    max-width: 395px;
}

.hidden {
    display: none !important;
}

.trustPilot{
    width: 100%;
    max-width: 1088px;
    margin: 0 auto 90px;
}

.trustPilot-subheader{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    margin-bottom: 32px;
}

.trustPilot-header{
    font-family: 'ABC Social Condensed', sans-serif;
    max-width: 850px;
    margin: 0 auto;
    font-weight: 700;
    font-size: 64px;
    line-height: 90%;
    text-align: center;
    color: #F7F7F7;
}

.trustPilot-text{
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 130%;
    color: #F8F9FA;
    margin-left: 15px;
    margin-top: 20px;
}

.trustPilot-text span,
.trustPilot-text a{
    color: #F2C94C;
}

.feedbackWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.feedbackStarsContainer{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.feedbackStarsContainer img{
    margin-right: 10px;
}

.feedbackItem {
    font-family: 'ABC Social Condensed', sans-serif;
    width: 350px;
    height: 404px;
    background: #262626;
    border-radius: 10px;
    padding: 22px 22px 30px 22px;
    display: flex;
    flex-direction: column;
}

.feedbackHeader {
    font-family: 'ABC Social Condensed', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 90%;
    color: #F7F7F7;
    margin-bottom: 10px;
}

.feedbackText {
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    color: #DFDFDF;
    margin-bottom: auto;
}

.feedbackNickName{
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 17px;
    line-height: 130%;
    color: #FFFFFF;
}

.game-selector-mobile {
    display: none;
}

@media (max-width: 850px) {
    .custom_amplitude {
        bottom: 60px;
    }

    .showOnDesktop {
        display: none;
    }

    .showOnMobile {
        display: block;
        align-self: flex-start;
    }

    .menu-block,
    .header-socials {
        display: none;
    }

    .header-buttons-block {
        position: relative;
    }

    .main-header {
        margin-left: 0;
        height: 180px;
        max-width: 630px;
    }

    .main-header span:not(:last-child) {
        font-size: 48px;
        line-height: 90%;
    }

    .main-info {
        margin-top: 35px;
        margin-left: 48px;
    }

    .main-block {
        padding-top: 101px;
        height: auto;
        background-size: contain;
    }

    .streamLobby {
        padding: 0 48px;
    }

    .streamLobbyHeader {
        font-weight: 700;
        font-size: 34px;
        line-height: 90%;
        max-width: 100%;
        margin: 50px 0 26px 0;
    }

    .noPro {
        margin: 0 48px;
        width: auto;
    }

    .noPro-header {
        text-transform: none;
    }

    .jobBlock {
        padding: 0 48px;
        gap: 0 24px;
        margin: 0 auto 128px;
    }

    .jobItem {
        padding: 24px;
        min-width: 49%;
    }

    .job-header {
        font-size: 24px;
    }

    .job-text {
        font-size: 16px;
    }

    footer {
        padding: 64px 48px;
        margin: 0 48px;
        width: auto;
    }

    .inner-wrapper{
        flex-wrap: wrap;
    }

    .footer-right {
        height: auto;
        margin: 0 auto;
    }

    .bottom-links {
        margin-top: 48px;
    }

    .looking-for {
        display: none;
    }

    .looking-for-tablet {
        display: block;
        margin-top: 96px;
        margin-bottom: 160px;
    }

    .looking-for-left {
        width: 100%;
        justify-content: center;
    }

    .streamLobbyDescription {
        width: 100%;
    }

    .streamLobbyFilter {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        border-radius: 8px;
        border: 1px solid rgba(255,255,255, 0.1);
        padding: 4px;
    }

    .streamLobbyFilter label {
        padding: 4px 8px;
        font-size: 19px;
        margin: 0;
        white-space: nowrap;
    }

    .streamLobbyFilter input:checked + label {
        background: #6800ED;
        color: #ffffff;
    }

    footer .mobile-copyright {
        display: none;
        margin-top: 32px;
        font-family: 'ABC Social', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 110%;
        color: #B2B2B2;
        margin-bottom: 0;
    }

    .swiper-slide {
        transform: scale(1) !important;
        -webkit-filter: grayscale(0) opacity(1)  !important;
    }

    .socials-wrapper {
        padding: 0 48px;
    }

    .looking-for-left ul {
        right: initial;
    }

    .streamLobbyItemTopWrapper {
        padding: 0 16px;
    }

    .streamLobbyItemPrice {
        display: none;
    }

    .streamLobbyItemNickNamesWrapper {
        width: 100%;
    }

    .streamLobbyItemStreamHeader {
        max-width: 90%;
    }

    .streamLobbyItemButtonText,
    .streamLobbyItemButtonValue {
        display: none;
    }

    .popup-modal {
        height: fit-content;
        border: 1px solid #333333;
        border-radius: 16px 16px 0 0;
        position: fixed;
        left: initial;
        bottom: 0;
        top: initial;
        transform: none;
        padding: 36px 16px 24px 16px;
    }

    .popup-modal-header {
        font-size: 40px;
        margin-bottom: 8px;
    }

    .sign-in-header {
        margin-bottom: 24px;
    }

    .log-in-email-header {
        margin-bottom: 24px;
    }

    .sign-in-email-form {
        flex-direction: column;
    }

    .sign-in-email-form .inputWrapper:first-child {
        margin-right: 0;
        margin-bottom: 16px;
    }

    .forgot-password-block {
        justify-content: center;
    }

    .log-in-form-button {
        margin-top: 24px;
    }

    .password-recovery-header {
        font-size: 40px;
        margin-bottom: 16px;
        text-align: center;
    }

    .popup-modal-description {
        font-size: 16px;
        margin-bottom: 24px;
    }

    .action-btns {
        flex-direction: column-reverse;
    }

    .send-pass {
        margin-right: 0;
    }

    .recover-pass-login {
        margin-bottom: 16px;
    }

    .popup-modal .discord-login-btn {
        height: 60px;
    }

    .popup-modal .log-in-button-text {
        margin-bottom: 24px;
    }

    .auth-buttons {
        flex-direction: column;
        margin-bottom: 24px;
    }

    .auth-buttons div:first-child {
        margin-right: 0;
        margin-bottom: 16px;
    }

    .terms-links {
        text-align: center;
        line-height: 110%;
        max-width: 210px;
    }

    .description-block {
        padding: 0 16px;
    }
}

@media (max-width: 725px) {
    .footer-left {
        height: auto;
        margin-bottom: 40px;
    }

    .footer-left p {
        display: none;
    }

    .mobile-copyright {
        display: block !important;
    }
}

@media (max-width: 590px) {
    .showOnDesktop {
        display: none !important;
    }

    .main-block {
        padding-top: 0;
    }

    .game-selector {
        position: sticky;
        transform: initial;
        left: initial;
        display: none;
    }

    .main-logo {
        width: 111px;
        height: 30px;
        margin-right: 0;
    }

    header {
        height: 48px;
        padding: 0 16px
    }

    footer {
        margin: 0;
        padding: 32px 16px 16px 16px;
    }

    .jobBlock {
        padding: 0 16px;
        flex-wrap: wrap;
        gap: 24px 0;
        margin-bottom: 32px;
    }

    .jobItem {
        width: 100%;
    }

    .description-block {
        max-width: initial;
        padding: 0 16px;
    }

    .descriptionBasicText {
        font-size: 14px;
    }

    .descriptionOptions {
        gap: 16px;
        flex-direction: column;
        justify-content: flex-start;
        margin: 24px 0;
    }

    .descriptionSecondText {
        margin-bottom: 48px;
    }

    .socials-block {
        margin-bottom: 48px;
        margin-top: 50px;
    }

    .socials-wrapper {
        padding: 0;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .socials-wrapper a {
        width: 33%;
        margin-bottom: 20px;
    }

    .main-info {
        margin-left: 16px;
        margin-top: 16px;
    }

    .main-header span:not(:last-child) {
        font-size: 40px;
        line-height: 90%;
    }

    .main-header {
        background-image: url("../img-main-page/main-header-mobile.svg");
        background-size: 100%;
        padding-left: 16px;
    }

    .main-header span:last-child {
        display: block;
        font-weight: 700;
        font-size: 37px;
        line-height: 90%;
        text-transform: uppercase;
        color: #F7F7F7;
    }

    .streamLobby {
        padding: 0 16px;
    }

    .noPro {
        margin: 0 16px;
        padding: 16px;
    }

    .noPro-header {
        font-size: 24px;
        line-height: 90%;
    }

    .noPro-text {
        font-size: 16px;
    }

    .looking-for-mobile {
        display: block;
        margin-top: 84px;
        margin-bottom: 33px;
    }

    .looking-for-tablet {
        display: none;
    }

    .looking-for-header {
        font-size: 40px;
        line-height: 90%;
    }

    .looking-for-left ul {
        left: 20px;
        padding: 0 16px;
    }

    .socials-header {
        font-size: 34px;
        line-height: 90%;
        text-transform: none;
    }

    .main-header {
        max-width: 83%;
    }

    .footer-right {
        width: 100%;
    }

    .job-button{
        width: 100%;
    }

    .feedbackWrapper{
        display: block;
        padding: 0 12px;
    }

    .feedbackItem{
        width: 100%;
        margin-bottom: 8px;
        padding-bottom: 19px;
        height: auto;
    }

    .trustPilot-header{
        font-size: 34px;
        line-height: 90%;
        max-width: 95%;
    }

    .trustPilot-subheader{
        display: block;
        text-align: center;
        margin-bottom: 16px
    }

    .trustPilot-subheader img{
        width: 164px;
    }

    .trustPilot-text{
        margin-left: 0;
        font-size: 18px;
    }

    .feedbackText{
        margin-bottom: 10px;
    }

    .trustPilot{
        margin-bottom: 60px;
    }

    .container-block {
        height: 180px;
        background-size: contain;
    }

    .container-block p {
        font-size: 44px;
    }

    .container-block span {
        font-weight: 700;
        font-size: 20px;
        line-height: 90%;
    }

    .main-block > .sign-up {
        margin-top: 5px;
    }

    .sign-up {
        padding: 0 16px;
    }

    .sign-up-form {
        flex-direction: column;
        width: 100%;
    }

    .sign-up-form > input {
        width: 100%;
    }

    .sign-up-form > button {
        width: 100%;
        margin-top: 10px;
    }

    .looking-for-left ul {
        font-weight: 400;
    }

    .game-selector-mobile {
        display: flex;
        position: sticky;
        bottom: 0;
        justify-content: center;
        z-index: 2;
    }

    .game-selector-mobile > .game-selector {
        display: flex;
        max-width: 100%;
        overflow-x: scroll;
    }

    .game-selector-mobile > .game-selector > a {
        min-width: 86px;
        height: 40px;
        padding: 9px 11px;
    }

    .pros-list h3 {
        font-size: 30px;
        margin-bottom: 12px;
    }

    .swiper-button-next,
    .swiper-button-prev {
        display: none !important;
    }

    .showAllButton {
        height: 60px;
        font-weight: 700;
        font-size: 20px;
        line-height: 110%;
        width: 95%;
    }

    .game-button > img {
        width: 100%;
    }

    .game-selector:before{
        display: none;
    }
}

@media (max-width: 450px) {
    .container-block p{
        font-size: 35px;
    }
}

.container-block{
    display: none;
}
